<template>
  <footer>
    <div class="footer-wrapper">
      <div class="footer-main">
        <!-- <img src="../../assets/images/HeaderLogo.png" alt="" /> -->
        <span>©2023 Selfex</span>
      </div>
      <div class="recent-block-wrapper">
        <span class="recent-block">
          {{ this.$store.state.latestBlock }}
          <div class="recent-block-tooltip">
            The most recent block number on this network. Prices update on every
            block.
          </div>
        </span>
      </div>
    </div>
    <div class="nav-item-container-bottom">
      <router-link to="/swap">
        <button class="nav-item">Swap</button> </router-link
      >|
      <router-link to="/pool">
        <button class="nav-item">Pool</button> </router-link
      >|
      <a href="https://info.uniswap.org/#/" target="_blank">
        <button class="nav-item">Charts<sup>↗</sup></button>
      </a>
    </div>
  </footer>
</template>

<script setup>
import { onMounted } from "vue";
import { useStore } from "vuex";
import web3 from "../../../ethereum/web3";

const store = useStore();

const getLatestBlockNumber = () => {
  setInterval(async () => {
    const latestBlock = await web3.eth.getBlockNumber();
    store.dispatch("setLatestBlock", latestBlock);
  }, 2000);
};

onMounted(() => {
  getLatestBlockNumber();
});
</script>

<style scoped>
footer > * {
  font-size: var(--global-font-size-small);
}
footer {
  max-width: 100%;
  width: var(--page-width);
  margin: 0 auto;
  border-top: var(--element-border);
  /* border-top: 1px solid transparent;
  -moz-border-image: linear-gradient(
    90deg,
    #20102c 9.69%,
    rgba(232, 91, 255, 0.64) 50.99%,
    #20102c 90.6%
  );
  -webkit-border-image: linear-gradient(
    90deg,
    #20102c 9.69%,
    rgba(232, 91, 255, 0.64) 50.99%,
    #20102c 90.6%
  );
  border-image: linear-gradient(
    90deg,
    #20102c 9.69%,
    rgba(232, 91, 255, 0.64) 50.99%,
    #20102c 90.6%
  );
  -webkit-border-image-slice: 2;
  border-image-slice: 2; */
}
.footer-wrapper {
  padding: 2.5rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.recent-block-wrapper {
  text-align: right;
}
.recent-block {
  display: inline-block;
  position: relative;
  color: var(--primary-color);
}
.recent-block-tooltip {
  display: none;
  position: absolute;
  top: -1rem;
  right: calc(100% + 1rem);
  width: 320px;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 6px;
  padding: 0.5rem;
  text-align: left;
  color: white;
  font-weight: 200;
  font-size: 12px;
}
.recent-block:hover .recent-block-tooltip {
  display: block;
}
.footer-main span {
  margin-top: -20px;
}
@media screen and (min-width: 800px) {
  .nav-item-container-bottom {
    display: none;
  }
}
@media screen and (max-width: 800px) {
  .nav-item-container-bottom {
    position: fixed;
    display: flex;
    bottom: 0;
    width: 100%;
    justify-content: space-around;
    background: var(--blur-bg);
    backdrop-filter: blur(0.8rem);
    /* -webkit-backdrop-filter: blur(10px); */
    border-radius: 1rem 1rem 0 0;
    align-items: center;
    /* margin-top: ; */
  }

  .nav-item {
    align-items: center;
  }
}
</style>
